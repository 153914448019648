var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "점검항목",
            columns: _vm.grid.columns,
            data: _vm.target.results,
            filtering: false,
            columnSetting: false,
            usePaging: false,
            editable: _vm.editable && !_vm.disabled,
            selection: "multiple",
            rowKey: "sopPreWorkCheckTargetResultId",
          },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "customCol"
                    ? [
                        _c(_vm.imprComponent, {
                          tag: "component",
                          attrs: {
                            col: col,
                            props: props,
                            inputEditable: _vm.editable && !_vm.disabled,
                            isImmShow: true,
                            requestContentsCols: _vm.requestContentsCols,
                            tableKey: "sopPreWorkCheckTargetResultId",
                            ibmTaskTypeCd: "ITT0000195",
                            ibmTaskUnderTypeCd: "ITTU000220",
                          },
                          on: { imprChange: _vm.imprChange },
                        }),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: {
                          showLoading: false,
                          label: "LBLADD",
                          icon: "add",
                        },
                        on: { btnClicked: _vm.addResult },
                      })
                    : _vm._e(),
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: {
                          disabled:
                            !_vm.target.results ||
                            _vm.target.results.length === 0,
                          label: "LBLREMOVE",
                          icon: "remove",
                        },
                        on: { btnClicked: _vm.deleteResult },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }